<template>
  <div
    class="relative w-[36px] h-[36px]"
    v-motion
    :initial="{ scale: 0.3, opacity: 0 }"
    :enter="{
      scale: 1,
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 260,
        damping: 30,
        mass: 0.5,
      },
    }"
  >
    <div
      v-motion
      :initial="{ scale: 1 }"
      :enter="{
        scale: 1.3,
        transition: {
          duration: 600,
          repeat: Infinity,
          repeatType: 'mirror',
        },
      }"
      class=""
    >
      <img class="relative z-10 w-full h-full" src="/logo.png" />
      <img
        class="loading absolute top-0 z-0"
        src="/images/icons/logo-background.png"
      />
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
@keyframes loading {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}

.loading {
  width: 100%;
  height: 100%;

  transform-origin: center;
  animation: loading 1.2s ease-in-out infinite;
}
</style>
